<template>
  <div class="header">
    <div class="logo" :class="{'is-active':isActive}">
      北海市干部教育培训管理系统
    </div>
    <div class="navbar">
      <div class="btn" :class="{'is-active':isActive}" @click="handleMenu">
        <span :class="menuBtn"></span>
      </div>
      <el-dropdown trigger="click" class="tliev">
        <span class="el-dropdown-link">
          <i class="el-icon-switch-button" style="font-size: 20px"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">退出 </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click" class="ther">
        <div class="head">
          <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" style="width: 24px;height: 24px" class="imgre"></el-avatar>
        </div>
        <span class="el-dropdown-link">
         {{this.$store.getters.username}}
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="personages()">修/填个人资料 </el-dropdown-item>
          <el-dropdown-item @click.native="updatepas()">修改密码 </el-dropdown-item>
          <el-dropdown-item></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dialog
        title="修改密码"
        :visible.sync="updata"
        width="30%">
        <el-form
          :model="ruleForm2"
          status-icon
          :rules="rules2"
          ref="ruleForm2"
          label-width="0"
          class="demo-ruleForm"
        >
          <el-form-item prop="username" label="用户名" label-width="150">
            <el-input placeholder="输入用户名" v-model="this.$store.getters.username" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="旧密码" label-width="150">
            <el-input placeholder="输入旧密码" v-model="ruleForm2.password" type="password"></el-input>
          </el-form-item>
          <el-form-item prop="passwords" label="新密码" label-width="150">
            <el-input placeholder="输入新密码" v-model="ruleForm2.passwords" type="password"></el-input>
          </el-form-item>
          <el-form-item prop="checkPass" label="旧密码" label-width="150">
            <el-input type="password" v-model="ruleForm2.checkPass" placeholder="确认密码"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
          </span>
      </el-dialog>
      <el-drawer
        title="填/修个人资料"
        :visible.sync="drawers"
        :show-close="showclose"
        :close-on-click-modal="showclose"
        size="100%">
        <el-form
          :model="adminForm"
          label-position="right"
          label-width="120px"
          :rules="rulesadmin"
          ref="adminForm">
              <el-form-item  label="姓名" prop="name">
                <el-input v-model="adminForm.name" style="width: 90%" placeholder="请输入姓名"></el-input>
              </el-form-item>
              <el-form-item label="部门" prop="depName">
                <el-input v-model="adminForm.depName" style="width: 90%" placeholder="请输入部门"></el-input>
              </el-form-item>
              <el-form-item label="职务" prop="duty">
                <el-input v-model="adminForm.duty" style="width: 90%" placeholder="请输入职务"></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="sex" placeholder="请选择性别">
                <el-select v-model="adminForm.sex" style="width: 90%">
                  <el-option value="0" label="男"></el-option>
                  <el-option value="1" label="女"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="出生日期" prop="birth">
                  <el-date-picker
                    v-model="adminForm.birth"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期"
                     style="width: 90%">
                  </el-date-picker>
              </el-form-item>
          <!-- <el-button @click="drawers = false">取 消</el-button> -->
          <el-button type="primary" @click="personagesBt('adminForm')">确定</el-button>
        </el-form>
      </el-drawer>
    </div>
    <el-dialog
      title="温馨提示"
      :visible.sync="centerDialogVisible"
      :show-close="showclose"
      :close-on-click-modal="showclose"
      width="30%"
      center>
      <span>您还没有完善个人信息，请点击右上角头像，下拉“填写个人信息”进行完善。</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="out()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { delCookie } from '@/utils/base'
  import { updPassword, adminMessage, checkAdmin, getWsMessage } from '@/api/user.js'
  export default {
    name: 'Header',
    data () {
      const password = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入旧密码'))
        } else {
          callback()
        }
      }
      const passwords = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入新密码'))
        } else {
          // if (this.ruleForm2.passwords !== '') {
          //   this.$refs.ruleForm2.validateField('checkPass')
          // }
          callback()
        }
      }
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm2.passwords) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        showclose: false,
        centerDialogVisible: false,
        drawers: false,
        menuBtn: 'el-icon-newfont-caidan',
        updata: false,
        ruleForm2: {
          password: '',
          passwords: ''
        },
        rules2: {
          password: [{
            validator: password,
            trigger: 'change'
          }],
          passwords: [{
            validator: passwords,
            trigger: 'change'
          }],
          checkPass: [{
            validator: validatePass2,
            trigger: 'change'
          }]
        },
        adminForm: {
          name: '',
          sex: '',
          birth: null,
          depName: '',
          duty: '',
          id: ''
        },
        rulesadmin: {
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          sex: [{ required: true, message: '请输入性别', trigger: 'blur' }],
          birth: [{ required: true, message: '请输入出生日期', trigger: 'blur' }],
          depName: [{ required: true, message: '请输入部门', trigger: 'blur' }],
          duty: [{ required: true, message: '请输入职务', trigger: 'blur' }]
         }
      }
    },
    computed: {
      // 如果左侧菜单打开，则旋转btn180度
      isActive () {
        return !this.$store.getters.sidebar.opened
      }
    },
    mounted() {
      this.information()
      // window.addEventListener('beforeunload', e => this.loginOut())
      // 退出浏览器或关闭窗口自动重置登陆状态
    },
    created () {
        window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.addEventListener('unload', e => this.unloadHandler(e))
    },
    destroyed () {
      window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
      window.removeEventListener('unload', e => this.unloadHandler(e))
    },
    methods: {
       beforeunloadHandler() {
        this.beforeUnloadtime = new Date().getTime()
      },
      unloadHandler(e) {
        this.time = new Date().getTime() - this.beforeUnloadtime
        // 判断是窗口关闭还是刷新
        if (this.time <= 5) {
          delCookie('role')
          delCookie('Authorization')
          delCookie('isLogin')
          delCookie('expireTime')
          delCookie('admin')
          delCookie('userMes')
          // 如果是登录状态，关闭窗口前，移除用户
          // if(!this.showLoginButton){
          //   $.ajax({
          //     url: '/pictureweb/user/remove',
          //     type: 'get',
          //     async:false, //或false,是否异步
          //   })
          // }
        }
      },
      ...mapMutations({
        bindLogout: 'BIND_LOGOUT'
      }),
      information () { // 判断是否填写完个人信息
        const userName = this.$store.getters.username
        checkAdmin(userName).then(resp => {
          if (resp.data.code === '0') {
            this.centerDialogVisible = true
          }
        })
      },
      out() {
        this.centerDialogVisible = false
        this.drawers = true
      },
      handleMenu () {
        this.$store.dispatch('ToggleSideBar')
      },
      logout () {
        console.log(111)
        this.$confirm('是否退出账户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delCookie('role')
          delCookie('Authorization')
          delCookie('userMes')
          this.bindLogout()
          this.$router.push({
            path: '/login'
          })
        }).catch(() => {
        })
      },
      updatepas() {
        this.updata = true
      },
      // 修改密码提交
      submitForm (ruleForm2) {
        this.$refs[ruleForm2].validate(valid => {
          if (valid) {
            const from = {
              username: this.$store.getters.username,
              password: this.ruleForm2.password,
              passwords: this.ruleForm2.passwords
            }
            updPassword(
              from
            ).then((resp) => {
              this[ruleForm2] = {}
              if (resp.data.code === '0') {
                this.$notify({
                  title: '成功',
                  message: '修改密码成功。',
                  duration: 2000,
                  type: 'success'
                })
                this.updata = false
              } else if (resp.data.code === '405') {
                this.$notify({
                  title: '错误',
                  message: '旧密码错误',
                  duration: 2000,
                  type: 'error'
                })
              } else {
                this.$notify({
                  title: '错误',
                  message: '服务器请求出错',
                  duration: 2000,
                  type: 'error'
                })
              }
            })
          }
        })
      },
      // 填修改个人信息回显
      personages() {
        this.drawers = true
       const userName = this.$store.getters.username
        getWsMessage(userName).then(resp => {
          if (resp.data.code === '200') {
            if (resp.data.data) {
              this.adminForm = resp.data.data
            }
            if (resp.data.data.sex === 0) {
              this.adminForm.sex = '男'
            } else if (resp.data.data.sex === 1) {
             this.adminForm.sex = '女'
            }
          }
        })
      },
      personagesBt(adminForm) { // 修改填写个人资料
       if (this.adminForm.sex === '男') {
          this.adminForm.sex = '0'
        } else if (this.adminForm.sex === '女') {
          this.adminForm.sex = '1'
        }
        this.$refs[adminForm].validate(valid => {
           if (valid) {
            const wsMessage = {
              id: this.adminForm.id,
              userName: this.$store.getters.username,
              name: this.adminForm.name,
              sex: this.adminForm.sex,
              birth: this.adminForm.birth,
              depName: this.adminForm.depName,
              duty: this.adminForm.duty
            }
            console.log(this.adminForm.duty)
            console.log(333333333333333333333)
            adminMessage(wsMessage).then(resp => {
              if (resp.data.code === '200') {
                this.$notify({
                  title: '成功',
                  message: '填写成功',
                  duration: 2000,
                  type: 'success'
                })
                this.drawers = false
              } else if (resp.data.code === '402') {
                this.$notify({
                  title: '成功',
                  message: '修改成功',
                  duration: 2000,
                  type: 'success'
                })
                this.drawers = false
              } else if (resp.data.code === '406') {
                this.$notify({
                  title: '错误',
                  message: '请勿重复完善',
                  duration: 2000,
                  type: 'error'
                })
              } else {
                this.$notify({
                  title: '错误',
                  message: '服务器请求出错',
                  duration: 2000,
                  type: 'error'
                })
              }
            })
           }
        })
      }
    }
  }
</script>

<style scoped lang="stylus">
  .header
    width 100%
    height 50px
    display flex
    background #486edc
    .logo
      position absolute
      left -140px
      width 600px
      height 50px
      background #486edc
      color #fff
      font-size 18px
      line-height 50px
      transition .4s ease
    .navbar
      flex 1
      display flex
      align-items center
      justify-content space-between
      padding 0 10px
      color #fff
      .btn
        height 50px
        line-height 50px;
        cursor pointer
        transition .4s ease
        &.is-active
          transform: rotateY(180deg)
        span
          font-size 28px
          line-height 50px
          transition 0.5s
          color #ffffff
          font-weight 400
      .el-dropdown-link
        color #fff
        font-weight bold
        cursor pointer

  @media all and (max-width: 768px) {
    .header .logo.is-active {
      width: 0;
      overflow: hidden;
    }
  }
  .tliev{
    position absolute
    left 96.7%
  }
  .ther{
    position absolute
    left 89%
  }
  .imgre{
    position absolute
    left -30px
    top -4px
  }
</style>
