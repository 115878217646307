import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import Full from '@/containers/Full'
import Eull from '../containersplus/Eull'
import { getCookie } from '@/utils/base'
const Login = () => import('@/views/users/Login.vue')
const Register = () => import('@/views/users/Register.vue')
const Dashboard = () => import('@/views/dashboard/Dashboard.vue')
const ClassList = () => import('@/views/dashboard/ClassList.vue')
const Diagram = () => import('@/views/diagram/Diagram.vue')
const Exams = () => import('@/views/exams/Exams.vue')
const Roster = () => import('@/views/training/Roster.vue')
const Leave = () => import('@/views/training/Leave.vue')
const Check = () => import('@/views/training/Check.vue')
const Authenticate = () => import('@/views/training/Authenticate.vue')
const Admin = () => import('@/views/users/Admin.vue')
const Home = () => import('@/views/home/Home.vue')
const Applys = () => import('@/views/apply/Applys.vue')
const Day = () => import('@/views/day/Day.vue')
const Leavefer = () => import('@/views/leavefer/Leavefer.vue')
const Checkfor = () => import('@/views/checkfor/Checkfor.vue')
const Assess = () => import('@/views/assess/Assess.vue')
const Report = () => import('@/views/gather/Report.vue')
const Summary = () => import('@/views/gather/Summary.vue')
const Train = () => import('@/views/gather/Train.vue')
const Course = () => import('@/views/gather/Course.vue')
const Users = () => import('@/views/management/Users.vue')
const Setting = () => import('@/views/management/Setting.vue')
const UnitManagement = () => import('@/views/management/UnitManagement.vue')
const Dictionaries = () => import('@/views/management/Dictionaries.vue')
const Jurisdiction = () => import('@/views/management/Jurisdiction.vue')
const Organization = () => import('@/views/management/Organization.vue')
const Classification = () => import('@/views/management/Classification.vue')
const Schedule = () => import('@/views/schedule/Schedule.vue')
const CurriculumList = () => import('@/views/schedule/CurriculumList.vue')
const Cultivate = () => import('@/views/cultivate/Cultivate.vue')
const Examination = () => import('@/views/question/Examination.vue')
const Examinations = () => import('@/views/online/Examinations.vue')
const Exercise = () => import('@/views/question/Exercise.vue')
const Presentation = () => import('@/views/presentation/Presentation.vue')
const Classinformation = () => import('@/views/dashboard/Classinformation.vue')
const Dyaclass = () => import('@/views/uplode/Dyaclass.vue')
const Curriculum = () => import('@/views/uplode/Curriculum.vue')
const task = () => import('@/views/cultivate/task.vue')
const taskAdd = () => import('@/views/cultivate/taskAdd.vue')
const correction = () => import('@/views/gather/correction.vue')
const findStuWork = () => import('@/views/gather/findStuWork.vue')
const findSubmitWork = () => import('@/views/gather/findSubmitWork.vue')
const comment = () => import('@/views/gather/comment.vue')
const test = () => import('@/views/online/test.vue')
const Const = () => import('@/views/question/Const.vue')
const updataConst = () => import('@/views/question/updataConst')
const Exhibition = () => import('@/views/presentation/Exhibition')
const error = () => import('@/views/error/404')
// const CourseNotice = () => import('@iewstice/CourseNotice.vue')
// const RegistrationApproval = () => import('@iewstice/RegistrationApproval.vue')
// const TrainingNotice = () => import('@iewstice/TrainingNotice.vue')
Vue.use(VueRouter)
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录',
      requireLogin: true,
      user: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      title: '管理员登录',
      requireLogin: true,
      user: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: '注册',
      requireLogin: true,
      user: true
    }
  },
  {
    path: '/',
    redirect: 'home',
    name: 'Eull',
    component: Eull,
    meta: {
      requireLogin: false,
      user: true
    },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
        meta: {
          title: 'home',
          requireLogin: false,
          user: true
        }
      },
      {
        path: 'test',
        name: 'test',
        component: test,
        meta: {
          title: 'test',
          // 在线考试
          requireLogin: false,
          user: true
        }
      },
      {
        path: 'task',
        name: 'task',
        component: task,
        meta: {
          title: 'task',
          // 在线作业
          requireLogin: false,
          user: true
        }
      },
      {
        path: 'taskAdd',
        name: 'taskAdd',
        component: taskAdd,
        meta: {
          title: 'taskAdd',
          // 在线作业新增
          requireLogin: true,
          user: true
        }
      },
      {
        path: '/apply',
        name: '/Applys',
        component: Applys,
        meta: {
          title: 'applys',
          requireLogin: false,
          user: true
        }
      },
      {
        path: '/day',
        name: '/Day',
        component: Day,
        meta: {
          title: 'day',
          requireLogin: false,
          user: true
        }
      },
      {
        path: '/leavefer',
        name: '/Leavefer',
        component: Leavefer,
        meta: {
          title: 'leavefer',
          requireLogin: false,
          user: true
        }
      },
      {
        path: '/checkfor',
        name: '/Checkfor',
        component: Checkfor,
        meta: {
          title: 'checkfor',
          requireLogin: false,
          user: true
        }
      },
      {
        path: '/Schedule',
        name: '/Schedule',
        component: Schedule,
        meta: {
          title: 'Schedule',
          requireLogin: false,
          user: true
        }
      },
      {
        path: '/CurriculumList',
        name: '/CurriculumList',
        component: CurriculumList,
        meta: {
          title: 'CurriculumList',
          requireLogin: true,
          user: true
        }
      },
      {
        path: '/Cultivate',
        name: '/Cultivate',
        component: Cultivate,
        meta: {
          title: 'Cultivate',
          requireLogin: false,
          user: true
        }
      },
      {
        path: '/Examinations',
        name: '/Examinations',
        component: Examinations,
        meta: {
          title: 'Examinations',
          requireLogin: false,
          user: true
        }
      },
      {
        path: '/assess',
        name: '/Assess',
        component: Assess,
        meta: {
          title: 'assess',
          requireLogin: false,
          user: true
        }
      }
    ]
  },
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Full',
    component: Full,
    meta: {
      // breadcrumb: '后台管理',
      requireLogin: true,
      user: false
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          // breadcrumb: '创建班级',
          title: 'dashboard',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/ClassList',
        name: 'ClassList',
        component: ClassList,
        meta: {
          // breadcrumb: '班级学员列表',
          title: 'ClassList',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/Const',
        name: 'Const',
        component: Const,
        meta: {
          // breadcrumb: '录入试题',
          title: 'Const',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/updataConst',
        name: 'updataConst',
        component: updataConst,
        meta: {
          // breadcrumb: '录入试题',
          title: 'updataConst',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/correction',
        name: 'correction',
        component: correction,
        meta: {
          // breadcrumb: '批改作业',
          title: 'correction',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/findStuWork',
        name: 'findStuWork',
        component: findStuWork,
        meta: {
          // breadcrumb: '批改作业-班级学员',
          title: 'findStuWork',
          requireLogin: true
        }
      },
      {
        path: '/findSubmitWork',
        name: 'findSubmitWork',
        component: findSubmitWork,
        meta: {
          // breadcrumb: '批改作业-班级学员',
          title: 'findSubmitWork',
          requireLogin: true
        }
      },
      {
        path: '/comment',
        name: 'comment',
        component: comment,
        meta: {
          // breadcrumb: '批改作业',
          title: 'comment',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/Dyaclass',
        name: 'Dyaclass',
        component: Dyaclass,
        meta: {
          // breadcrumb: '课程安排',
          title: 'Dyaclass',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/Curriculum',
        name: 'Curriculum',
        component: Curriculum,
        meta: {
          // breadcrumb: '课程安排',
          title: 'Curriculum',
          requireLogin: true,
          user: false
        }
      },
      // {
      //   path: '/TrainingNotice',
      //   name: 'TrainingNotice',
      //   component: TrainingNotice,
      //   meta: {
      //     // breadcrumb: '培训管理',
      //     title: 'TrainingNotice',
      //     requireLogin: true
      //   }
      // },
      // {
      //   path: '/CourseNotice',
      //   name: 'CourseNotice',
      //   component: CourseNotice,
      //   meta: {
      //     // breadcrumb: '课程通知',
      //     title: 'CourseNotice',
      //     requireLogin: true
      //   }
      // },
      // {
      //   path: '/RegistrationApproval',
      //   name: 'RegistrationApproval',
      //   component: RegistrationApproval,
      //   meta: {
      //     // breadcrumb: '报名审批',
      //     title: 'RegistrationApproval',
      //     requireLogin: true
      //   }
      // },
      {
        path: '/Examination',
        name: 'Examination',
        component: Examination,
        meta: {
          // breadcrumb: '在线考试',
          title: 'Examination',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/Exercise',
        name: 'Exercise',
        component: Exercise,
        meta: {
          // breadcrumb: '在线考试',
          title: 'Exercise',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/Presentation',
        name: 'Presentation',
        component: Presentation,
        meta: {
          // breadcrumb: '综合数据',
          title: 'Presentation',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/Exhibition',
        name: 'Exhibition',
        component: Exhibition,
        meta: {
          // breadcrumb: '数据显示',
          title: 'Exhibition',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/diagram',
        name: 'Diagram',
        component: Diagram,
        meta: {
          // breadcrumb: '审核管理',
          title: 'diagram',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/exams',
        name: 'Exams',
        component: Exams,
        meta: {
          // breadcrumb: '分组管理',
          title: 'exams',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/Roster',
        name: 'Roster',
        component: Roster,
        meta: {
          // breadcrumb: '分组花名册',
          title: 'Roster',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/Leave',
        name: 'Leave',
        component: Leave,
        meta: {
          // breadcrumb: '请假管理',
          title: 'Leave',
          requireLogin: true,
          user: false
        }
      },
      {
        path: '/Check',
        name: 'Check',
        component: Check,
        meta: {
          // breadcrumb: '考勤管理',
          title: 'Check',
          requireLogin: true
        }
      },
      {
        path: '/authenticate',
        name: 'Authenticate',
        component: Authenticate,
        meta: {
          // breadcrumb: '培训鉴定',
          title: 'Authenticate',
          requireLogin: true
        }
      },
      {
        path: '/Report',
        name: 'Report',
        component: Report,
        meta: {
          // breadcrumb: '培训鉴定',
          title: 'Report',
          requireLogin: true
        }
      },
      {
        path: '/Summary',
        name: 'Summary',
        component: Summary,
        meta: {
          // breadcrumb: '培训鉴定',
          title: 'Sunmmary',
          requireLogin: true,
          admin: true
        }
      },
      {
        path: '/Train',
        name: 'Train',
        component: Train,
        meta: {
          // breadcrumb: '班级评估',
          title: 'Train',
          requireLogin: true
        }
      },
      {
        path: '/Course',
        name: 'Course',
        component: Course,
        meta: {
          // breadcrumb: '班级评估',
          title: 'Course',
          requireLogin: true
        }
      },
      {
        path: '/Jurisdiction',
        name: 'Jurisdiction',
        component: Jurisdiction,
        meta: {
          // breadcrumb: '培训鉴定',
          title: 'jurisdiction',
          requireLogin: true
        }
      },
      {
        path: '/Classinformation',
        name: 'Classinformation',
        component: Classinformation,
        meta: {
          // breadcrumb: '查看班级',
          title: 'Classinformation',
          requireLogin: true,
          admin: true
        }
      },
      {
        path: '/Setting',
        name: 'Setting',
        component: Setting,
        meta: {
          // breadcrumb: '系统设置',
          title: 'Setting',
          requireLogin: true
        }
      },
      {
        path: '/Organization',
        name: 'Organization',
        component: Organization,
        meta: {
          // breadcrumb: '学员管理',
          title: 'Organization',
          requireLogin: true
        }
      },
      {
        path: '/Users',
        name: 'Users',
        component: Users,
        meta: {
          // breadcrumb: '用户管理',
          title: 'Users',
          requireLogin: true
        }
      },
      {
        path: '/UnitManagement',
        name: 'UnitManagement',
        component: UnitManagement,
        meta: {
          // breadcrumb: '单位管理',
          title: 'UnitManagement',
          requireLogin: true
        }
      },
      {
        path: '/Dictionaries',
        name: 'Dictionaries',
        component: Dictionaries,
        meta: {
          // breadcrumb: '坐标管理',
          title: 'Dictionaries',
          requireLogin: true
        }
      },
      {
        path: '/Classification',
        name: 'Classification',
        component: Classification,
        meta: {
          // breadcrumb: '培训鉴定',
          title: 'Classification',
          requireLogin: true
        }
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: error,
    meta: {
      title: '404',
      requireLogin: true,
      user: true
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) { // 路由发生变化修改页面title
    document.title = '北海市干部教育培训管理系统'
  }
  if (!getCookie('userMes')) {
    console.log('111')
    if (to.meta.user) {
      if (store.getters.username) {
        if (Object.keys(from.query).length === 0) { // 判断路由来源是否有query，处理不是目的跳转的情况
          next()
        } else {
          const redirect = from.query.redirect // 如果来源路由有query
          if (to.path === redirect) { // 避免 next 无限循环
            next()
          } else {
            next({ path: redirect }) // 跳转到目的路由
          }
        }
      } else {
        const redirect = from.query.redirect // 如果来源路由有query
        if (to.path === redirect) { // 避免 next 无限循环
          next()
        } else {
          next({ path: redirect }) // 跳转到目的路由
        }
      }
    } else {
      if (store.getters.username) {
        next('/')
      } else {
      }
    }
  } else {
    if (to.meta.requireLogin) {
    console.log(to.meta)
      if (store.getters.username) {
        if (Object.keys(from.query).length === 0) { // 判断路由来源是否有query，处理不是目的跳转的情况
          next()
        } else {
          const redirect = from.query.redirect // 如果来源路由有query
          if (to.path === redirect) { // 避免 next 无限循环
            next()
          } else {
            next({ path: redirect }) // 跳转到目的路由
          }
        }
      } else {
        next({
          path: '/login',
          query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
      }
    } else {
      if (getCookie('admin')) {
          next({
               path: '/Classinformation',
               query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
      } else {
        next()
      }
    }
  }
})

export function resetRouter() {
  const newRouter = router()
  router.matcher = newRouter.matcher // the relevant part
}
export default router
