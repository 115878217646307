import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './permission'
import permission from '@/directive/permission/index.js' // 权限判断指令
import VeLine from 'v-charts/lib/line.common'
import VeMap from 'v-charts/lib/map.common'
import VeHistogram from 'v-charts/lib/histogram.common'
import VePie from 'v-charts/lib/pie.common'
import VeRing from 'v-charts/lib/ring.common'
import VeRadar from 'v-charts/lib/radar.common'

// import VCharts from 'v-charts'
// 下面的依赖可以按照需求选择性加载
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/markPoint'
import 'echarts/lib/component/markArea'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/title'
import 'zrender/lib/svg/svg'
import 'v-charts/lib/style.css'

[VeLine, VeMap, VeHistogram, VePie, VeRing, VeRadar].forEach(comp => {
  Vue.component(comp.name, comp)
})

Vue.directive('permission', permission)

Vue.use(ElementUI)
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
