import axios from 'axios'
import { getCookie, setCookie, delCookie } from '@/utils/base'
import { MessageBox, Message } from 'element-ui'
// import router from '@/router'

const service = axios.create({
  // baseURL: 'http://192.168.0.105:8089',
  // baseURL: 'http://smss4q.natappfree.cc',
  baseURL: 'https://api.bhgbzx.gov.cn',
  timeout: 30 * 1000
})
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    // if (getCookie('token')) { // 头部携带token
    //   config.headers.Authorization = getCookie('token')
    // }
    if (getCookie('Authorization')) { // 头部携带token
      config.headers.Authorization = getCookie('Authorization')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(resp => {
    if (resp.headers.authorization) {
      setCookie('Authorization', resp.headers.authorization)
    }
    // if (resp.headers.authorization) {
    //   setCookie('token', resp.headers.authorization)
    // }
    return resp
  }, function (error) {
    if (error.response.status === 401) { // 捕获401异常
      if (error.response.data.code === 4012) {
        delCookie('Authorization')
        // delCookie('token')
        MessageBox.alert('登录已过期，请重新登录', '错误', {
          confirmButtonText: '确定',
          type: 'error'
        }).then(() => {
          location.href = '/Login'
        })
      } else {
        Message({
          message: error.response.data.msg + '（请联系系统管理员）',
          type: 'error',
          duration: 5 * 1000
        })
      }
    } else if (error.response.status === 500) {
      if (!error.response.data.msg === 'Token已过期(Token expired or incorrect.)') {
        Message({
          message: error.response.status + '（请联系系统管理员）',
          type: 'error',
          duration: 5 * 1000
        })
      }
    } else {
      Message({
        message: error.response.status + '（请联系系统管理员）',
        type: 'error',
        duration: 5 * 1000
      })
    }
  }
)

export default service
export function getUrl () {
  // return 'http://beihai.free.idcfengye.com'
  //  return 'http://192.168.0.105:8089'
   return 'https://api.bhgbzx.gov.cn'
}
