<template>
  <div class="sidebar">
    <el-menu
      :default-active="activeIndex"
      router
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      background-color=""
      text-color=""
      active-text-color=""
      @open="handleOpen"
      @close="handleClose"
    >
      <el-menu-item index="/home">
        <i class="el-icon-user-solid"></i>
        <span slot="title">在线报名</span>
      </el-menu-item>
      <el-menu-item index="/apply">
        <i class="el-icon-user"></i>
      <span slot="title">报名信息</span>
      </el-menu-item>
      <el-menu-item index="/leavefer">
        <i class="el-icon-s-management"></i>
        <span slot="title">请假申请</span>
      </el-menu-item>
      <el-menu-item index="/schedule">
        <i class="el-icon-s-order"></i>
        <span slot="title">课程安排</span>
      </el-menu-item>
      <el-menu-item index="/Cultivate">
        <i class="el-icon-s-marketing"></i>
        <span slot="title">培训评估</span>
      </el-menu-item>
      <el-menu-item index="/task">
        <i class="el-icon-document-checked"></i>
        <span slot="title">在线作业</span>
      </el-menu-item>
      <el-menu-item index="/Examinations">
        <i class="el-icon-document-copy"></i>
        <span slot="title">在线考试</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'Sidebar',
    inject: ['reload'],
    data () {
      return {
        // nav:[
        //   {title:'在线报名',icon:'el-icon-user-solid',url:'/home'},
        //   {title:'报名信息',icon:'el-icon-user',url:'/apply'},
        //   {title:'请假说明',icon:'el-icon-s-management',url:'/leavefer'},
        //   {title:'日程安排',icon:'el-icon-user-solid',url:'/home'},
        //   {title:'在线报名',icon:'el-icon-user-solid',url:'/home'},
        //   {title:'在线报名',icon:'el-icon-user-solid',url:'/home'}
        // ],
        imgLy: require('../../assets/img/路径 96.png')
      }
    },
    computed: {
      ...mapGetters([
        'sidebar'
      ]),
      isCollapse () {
        return !this.sidebar.opened
      },
      activeIndex () {
        const thisRoutPath = this.$route.path
        if (this.$route.name === 'details') {
          return '/tables/details'
        }
        return thisRoutPath
      }
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath)
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath)
      }
    }
  }
</script>

<style scoped>
/* .sidebar{ box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)} */
.el-menu{ border-right:none}
.el-menu-item{color: #303133;}
.el-menu-item.is-active{color:#409eff;}
.el-menu-item.is-active[data-v-4d6e0e73], .el-submenu.is-active[data-v-4d6e0e73] .el-submenu__title{background: #f2f2f2 !important}
  .el-submenu.is-active .el-menu-item-group{
    background-color:white;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 160px;
    min-height: 100vh;
    height: 100%;
  }

  .el-menu-vertical-demo {
    min-height: 100vh;
    height: 100%;
  }

  .el-menu-item.is-active,
  .el-submenu.is-active >>> .el-submenu__title {
    background: #cccccc !important;
  }

  .el-submenu .el-menu-item {
    padding-left: 60px !important;
    min-width: auto;
  }

  .el-submenu.is-active .el-menu-item {
    background: white !important;
  }

  .el-submenu.is-active .el-menu-item.is-active {
    background: white !important;
  }
  .el-submenu .el-menu-item:hover {
    padding-left: 60px !important;
    background: #cccccc !important;
  }
  .el-menu-item i {
    padding-bottom: 4px;
  }

  .el-submenu__title i {
    padding-bottom: 4px;
  }

  @media all and (max-width: 768px) {
    .el-menu--collapse {
      width: 0;
      overflow: hidden;
    }
  }
</style>
