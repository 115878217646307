import router from './router'

import { getCookie } from '@/utils/base'

const whiteList = ['/login', '/admin', '/register']
// 在每个路由生效之前，先进行一些处理，请参考 vue-router官方文档-导航钩子
router.beforeEach((to, from, next) => {
  const isLogin = getCookie('isLogin')
  console.log(isLogin)
  if (isLogin) {
    next()
  } else {
    if (whiteList.indexOf(to.path.toLowerCase()) !== -1) {
      next()
    } else {
      next('/Login')
    }
  }
})
