import Vue from 'vue'
import Vuex from 'vuex'
import user from './Modules/user'
import getters from './getters'
import app from './Modules/app'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    app
  },
  getters
})
