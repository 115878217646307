<template>
  <div class="header">
    <div class="logo" :class="{'is-active':isActive}">
      北海市干部教育培训信息系统
    </div>
    <div class="navbar">
      <div class="btn" :class="{'is-active':isActive}" @click="handleMenu">
        <span :class="menuBtn"></span>
      </div>
      <el-dropdown trigger="click" class="loGout">
        <span class="el-dropdown-link">
          <i class="el-icon-switch-button" style="font-size: 20px"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">退出 </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click" class="father">
        <div class="head">
          <el-avatar icon="el-icon-user-solid" style="width: 24px;height: 24px"></el-avatar>
        </div>
        <span class="el-dropdown-link">
         {{this.$store.getters.username}}
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item @click.native="personage()">查看个人资料 </el-dropdown-item> -->
          <el-dropdown-item @click.native="personages()">修/填个人资料 </el-dropdown-item>
         <el-dropdown-item @click.native="updatepas()">修改密码 </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dialog
        title="修改密码"
        :visible.sync="updata"
        width="30%">
        <el-form
          :model="ruleForm2"
          status-icon
          :rules="rules2"
          ref="ruleForm2"
          label-width="0"
          class="demo-ruleForm"
        >
          <el-form-item prop="username" label="用户名" label-width="150">
            <el-input placeholder="输入用户名" v-model="this.$store.getters.username" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="旧密码" label-width="150">
            <el-input placeholder="输入旧密码" v-model="ruleForm2.password" type="password"></el-input>
          </el-form-item>
          <el-form-item prop="passwords" label="新密码" label-width="150">
            <el-input placeholder="输入新密码必须由小写字母、大写字母、数字、特殊符号" v-model="ruleForm2.passwords" type="password"></el-input>
          </el-form-item>
          <el-form-item prop="checkPass" label="确认密码" label-width="150">
            <el-input type="password" v-model="ruleForm2.checkPass" placeholder="确认密码"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
          </span>
      </el-dialog>
      <el-drawer
        title="查看个人资料"
        :visible.sync="drawer"
        size="50%">
          <el-form
            :model="school"
            label-position="right"
            label-width="120px"
            ref="school"
            class="big-fa">
            <div class="big">
              <div class="left">
                <el-form-item  label="姓名">
                  <el-input  v-model="school.stuName" autocomplete="off" style="width: 90%" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                  <el-select v-model="school.sex" disabled  style="width: 90%">
                    <el-option value="0" label="男"></el-option>
                    <el-option value="1" label="女"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="职务">
                  <el-select
                    v-model="school.stuDuty"
                    class="filter-item"
                    filterable
                    clearable
                    placeholder="请输入职务"
                    disabled
                    style="width: 90%"
                  >
                    <el-option v-for="(i) in selectDutyList" :key="i.value" :label="i.value" :value="i.value" disabled>{{i.value}}</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item  label="干部层级">
                  <el-input  v-model="school.stuLeadDuty" autocomplete="off" style="width: 90%" :disabled="true"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="出生日期">
                  <el-date-picker
                    v-model="school.stuBirth"
                    value-format="yyyy-MM-dd "
                    type="date"
                    placeholder="选择日期"
                     style="width: 90%">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="单位名称">
                  <el-input v-model="school.depName" style="width: 90%" class="filter-item"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                  <el-input  v-model="school.stuPhone" style="width: 90%" autocomplete="off" :disabled="true"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
      </el-drawer>
      <el-drawer
        title="填/修个人资料"
        :visible.sync="drawers"
        :show-close="showclose"
        :close-on-click-modal="showclose"
        size="50%">
        <el-form
          :model="school"
          label-position="right"
          label-width="120px"
          :rules="rules"
          ref="school">
              <el-form-item label="姓名" prop="stuName">
                <el-input v-model="school.stuName" autocomplete="off" style="width: 90%" ></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="sex">
                <el-select v-model="school.sex" style="width: 90%" >
                  <el-option value="0" label="男"></el-option>
                  <el-option value="1" label="女"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="干部层级" prop="stuLeadDuty">
                 <el-select v-model="school.stuLeadDuty" style="width: 90%" >
                    <el-option v-for="(i) in dictionaryList4" :key="i.value" :label="i.value" :value="i.value"></el-option>
                 </el-select>
              </el-form-item>
              <el-form-item label="单位名称" prop="depName">
                  <el-cascader
                    :change-on-select="true"
                    :props="defaultParamsOne"
                    :options="optionsOne"
                    :clearable="true"
                    :show-all-levels="false"
                    v-model="school.depName"
                    filterable
                    style="width: 90%" ></el-cascader>
              </el-form-item>
              <el-form-item label="职务" prop="stuDuty">
                <el-input  v-model="school.stuDuty" style="width: 90%" placeholder="请输入职务"></el-input>
              </el-form-item>
              <el-form-item label="出生日期" prop="stuBirth">
                <el-date-picker
                  v-model="school.stuBirth"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期"
                  style="width: 90%" >
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="联系电话" prop="stuPhone">
                <el-input  v-model="school.stuPhone" style="width: 90%" autocomplete="off" maxlength="11"></el-input>
              </el-form-item> -->
              <el-form-item label="职级" prop="duty">
                  <el-cascader
                    :change-on-select="true"
                    :props="defaultParams"
                    :options="optionsTwo"
                    :clearable="true"
                    :show-all-levels="false"
                    v-model="school.duty"
                    filterable
                     @change="qingjiaChange"
                     style="width: 90%" ></el-cascader>
              </el-form-item>
        </el-form>
        <el-button @click="drawers = false">取 消</el-button>
        <el-button type="primary" @click="insertStu('school')">确定</el-button>
        <div>
          <el-drawer>
          </el-drawer>
        </div>
      </el-drawer>
    </div>
    <el-dialog
      title="温馨提示"
      :visible.sync="centerDialogVisible"
      :show-close="showclose"
      :close-on-click-modal="showclose"
      width="30%"
      center>
      <span>您还没有完善个人信息，请点击右上角头像，下拉“填写个人信息”进行完善，才可以报名。</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="out()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { insertStu, GetList, updPassword, GetAllById, showTreesByRedis, weanStudent, showTreeByRedis } from '@/api/user.js'
  import { mapMutations } from 'vuex'
  import { delCookie } from '@/utils/base'
  export default {
    name: 'Header',
    data () {
    const password = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入旧密码'))
        } else {
          callback()
        }
      }
      const passwords = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入新密码必须由小写字母、大写字母、数字、特殊符号'))
        } else {
          // if (this.ruleForm2.passwords !== '') {
          //   this.$refs.ruleForm2.validateField('checkPass')
          // }
          callback()
        }
      }
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm2.passwords) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        showclose: false,
        centerDialogVisible: false,
        drawers: false,
        updata: false,
        dictionaryList4: [],
        selectDutyList: [],
        school: {
          stuName: '',
          stuDuty: '',
          sex: '',
          stuBirth: '',
          depName: '',
          stuPhone: '',
          duty: [],
          // tudy: '',
          stuLeadDuty: ''
        },
        drawer: false,
        tible: '填写个人资料',
        Updataimformetion: false,
        menuBtn: 'el-icon-newfont-caidan',
        ruleForm2: {
          password: '',
          passwords: ''
        },
        rules2: {
          password: [{
            validator: password,
            trigger: 'change'
          }],
          passwords: [{
            validator: passwords,
            trigger: 'change'
          }],
          checkPass: [{
            validator: validatePass2,
            trigger: 'change'
          }]
        },
        optionsOne: [],
        defaultParamsOne: {
          label: 'label',
          value: 'label',
          children: 'children'
        },
        showPrise: false,
        showPriseTwo: false,
        optionsTwo: [],
        defaultParams: {
          label: 'label',
          value: 'label',
          children: 'children'
        },
        rules: {
          stuName: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
          sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
          stuLeadDuty: [{ required: true, message: '请选择干部层级', trigger: 'change' }],
          depName: [{ required: true, message: '请选择单位名称', trigger: 'change' }],
          duty: [{ required: true, message: '请填写职级', trigger: 'change' }],
          stuBirth: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
          // stuPhone: [{ required: true, message: '请填写联系电话', trigger: 'blur' }],
          stuDuty: [{ required: true, message: '请选择职务', trigger: 'blur' }]
        }
      }
    },
    mounted() { // 钩子函数
      this.GetAllById(300) // 职务
     // this.showTreeByRedis() // 职务
    //  this.showTree() // 从缓存中职级
     this.showTreesByRedis() // 从缓存中职级
     this.information() // 初始化信息
     this.showTreeByRedis() // 从缓存单位名称
    },
    computed: {
      // 如果左侧菜单打开，则旋转btn180度
      isActive () {
        return !this.$store.getters.sidebar.opened
      }
    },
    created () {
        window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.addEventListener('unload', e => this.unloadHandler(e))
    },
    destroyed () {
      window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
      window.removeEventListener('unload', e => this.unloadHandler(e))
    },
    methods: {
      // 获取单位名称列表数据
      showTreeByRedis () {
        showTreeByRedis().then(resp => {
          if (resp.data.code === '200') {
            this.optionsOne = resp.data.data.dictionaryTrees
          }
        })
      },
      beforeunloadHandler() {
        this.beforeUnloadtime = new Date().getTime()
      },
      unloadHandler(e) {
        this.time = new Date().getTime() - this.beforeUnloadtime
        // 判断是窗口关闭还是刷新
        if (this.time <= 5) {
          delCookie('isLogin')
          delCookie('expireTime')
          // 如果是登录状态，关闭窗口前，移除用户
          // if(!this.showLoginButton){
          //   $.ajax({
          //     url: '/pictureweb/user/remove',
          //     type: 'get',
          //     async:false, //或false,是否异步
          //   })
          // }
        }
      },
      ...mapMutations({
        bindLogout: 'BIND_LOGOUT'
      }),
      information () { // 判断是否填写完个人信息
        const userName = this.$store.getters.username
        weanStudent(userName).then(resp => {
          if (resp.data.code === '200') {
            if (resp.data.data === 0) {
              this.centerDialogVisible = true
            }
          }
        })
      },
      out() {
        this.centerDialogVisible = false
        this.drawers = true
      },
      // 修改个人资料回显
      personages() {
        this.drawers = true
        const usernam = this.$store.getters.username
        GetList(usernam).then(resp => {
          if (resp.data.code === '200') {
            if (resp.data.data[0]) {
              this.school = resp.data.data[0] // 接收返回的数据
              this.school.depName = this.school.depName.split(',')
              this.school.duty = this.school.duty.split(',')
            }
          }
        })
      },
      updatepas() {
        const userid = this.$store.getters.username
        this.updata = true
        console.log(userid)
      },
      handleMenu () {
        this.$store.dispatch('ToggleSideBar')
      },
      logout () {
        this.$confirm('是否退出账户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delCookie('isLogin')
          delCookie('admin')
          setTimeout(() => {
            this.$router.push({
              path: '/Login'
            })
          }, 500)
        }).catch(() => {
        })
      },
      // 字典表
      GetAllById (id) {
        GetAllById(id).then(resp => {
          if (resp.data.code === '0') {
              if (id === 300) {
                this.dictionaryList4 = resp.data.data
              }
          }
        })
      },
      // showTree () {
      //   const id = 400
      //   showTree(id).then(resp => {
      //     if (resp.data.code === '0') {
      //     }
      //   })
      // },
       // 职级缓存
      showTreesByRedis() {
        showTreesByRedis().then(resp => {
          if (resp.data.code === '200') {
            // 将获得到的数据赋值给options
            this.optionsTwo = resp.data.data.dictionaryTrees
          }
        })
      },
      // 选择请假列表 值变化
      qingjiaChange (val) {
        console.log(val)
      },
      // 填、修改个人资料提交
      insertStu (school) {
        if (this.school.sex === '男') {
          this.school.sex = 0
        } else if (this.school.sex === '女') {
          this.school.sex = 1
        }
        this.$refs[school].validate((valid) => {
          if (valid) {
            const student = {
              classId: this.school.id,
              stuName: this.school.stuName,
              stuBirth: this.school.stuBirth,
              sex: this.school.sex,
              depName: this.school.depName + '',
              duty: this.school.duty + '',
              stuPhone: this.$store.getters.username,
              // stuPhone: this.school.stuPhone,
              stuLeadDuty: this.school.stuLeadDuty,
              stuDuty: this.school.stuDuty,
              userNameToken: this.$store.getters.username
            }
            insertStu(student).then(resp => {
              this[school] = {}
              if (resp.data.code === '0') {
                this.$notify({
                  title: '成功',
                  message: '修改资料成功。',
                  duration: 1000,
                  type: 'success'
                })
                this.drawers = false
              }
            })
          }
        })
      },
      // 修改密码提交
      submitForm (ruleForm2) {
        this.$refs[ruleForm2].validate(valid => {
          if (valid) {
            const from = {
              username: this.$store.getters.username,
              password: this.ruleForm2.password,
              passwords: this.ruleForm2.passwords
            }
            updPassword(
              from
            ).then((resp) => {
              this[ruleForm2] = {}
              if (resp.data.code === '0') {
                this.$notify({
                  title: '成功',
                  message: '修改密码成功。',
                  duration: 2000,
                  type: 'success'
                })
                this.updata = false
              } else if (resp.data.code === '406') {
                this.$notify({
                  title: '错误',
                  message: resp.data.msg,
                  duration: 2000,
                  type: 'error'
                })
              } else if (resp.data.code === '405') {
                this.$notify({
                  title: '错误',
                  message: '旧密码错误',
                  duration: 2000,
                  type: 'error'
                })
              } else {
                this.$notify({
                  title: '错误',
                  message: '服务器请求出错',
                  duration: 2000,
                  type: 'error'
                })
              }
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="stylus">
  .header
    width 100%
    height 50px
    display flex
    background #486edc
    .logo
      position absolute
      left -60px
      width 500px
      height 50px
      background #486edc
      color #fff
      font-size 18px
      line-height 50px
      font-weight 600
      transition .4s ease
    .navbar
      flex 1
      display flex
      align-items center
      justify-content space-between
      padding 0 10px
      color #fff
      .btn
        height 50px
        line-height 50px;
        cursor pointer
        transition .4s ease
        &.is-active
          transform: rotateY(180deg)
        span
          font-size 28px
          line-height 50px
          transition 0.5s
          color #ffffff
          font-weight 400
      .el-dropdown-link
        color #fff
        font-weight bold
        cursor pointer
  @media all and (max-width: 768px) {
    .header .logo.is-active {
      width: 0;
      overflow: hidden;
    }
  }
  .loGout{
    position absolute
    font-size 24px
    left  96.7%
  }
  .infometion{
    position absolute
    left 1160px
  }
  .father{
    position absolute
    left 89%
  }
  .head{
    position absolute
    left -30px
    top -4px
  }
</style>
