<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-container>
      <Siderbar></Siderbar>
      <el-container>
        <el-main>
          <Breadcrumb></Breadcrumb>
          <router-view></router-view>
        </el-main>
        <el-footer>
          <Footer></Footer>
        </el-footer>
      </el-container>
    </el-container>
    <Scrolltotop></Scrolltotop>
  </el-container>
</template>

<script>
  import Header from '../componentsplus/header/Headerone'
  import Breadcrumb from '../componentsplus/breadcrumbone/Breadcrumbone'
  import Footer from '../componentsplus/footer/Footerone'
  import Siderbar from '../componentsplus/sidebar/Sidebarone'
  import Scrolltotop from '../componentsplus/scrolltotop/Scrolltotopone'
  export default {
    name: 'Eull',
    components: {
      Header,
      Breadcrumb,
      Footer,
      Siderbar,
      Scrolltotop
    },
    computed: {
    },
    created () {
    },
    methods: {
    }
  }
</script>

<style scoped lang="stylus">
  @media (max-width: 920px)
    .el-main
      padding 0

  .el-header, .el-footer {
    color: #333;
    height: 50px !important;
    padding: 0;
  }

  .el-main {
    background-color: #f5f5f5;
    color: #333;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

</style>
