<template>
  <div class="sidebar">
    <el-menu
      :default-active="activeIndex"
      router
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      background-color=""
      text-color=""
      active-text-color=""
      @open="handleOpen"
      @select="handleselect"
      @close="handleClose"
    >
      <el-submenu :index="k + ''" v-for="(item, k) in showNavData" :key="k">
        <template slot="title">
          <span><i :class="item.meta.icon"></i>{{item.meta.title}}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-for="(children, i) in item.children" :key="i" :index="children.path">
           {{children.meta.title}}
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import routeJson from '@/router/route.json'
  import { getCookie } from '@/utils/base'
  export default {
    name: 'Sidebar',
    inject: ['reload'], // 注入reload方法
    data () {
      return {
        showNavData: [],
        adminNav: getCookie('admin'),
        display: 'none'
      }
    },
    computed: {
      ...mapGetters([
        'sidebar'
      ]),
      isCollapse () {
        return !this.sidebar.opened
      },
      activeIndex () {
        const thisRoutPath = this.$route.path
        console.log(thisRoutPath)
        if (this.$route.name === 'details') {
          return '/tables/details'
        }
        return thisRoutPath
      }
    },
    mounted() {
      this.asyncRouterMap()
    },
    methods: {
      asyncRouterMap() {
        const adminPermsString = getCookie('perms')// 后台返回菜单权限列表
        const adminPerms = adminPermsString.split(',')
        const mapArray = routeJson// 路由分离出来后台菜单josn
        mapArray.forEach(item => {
          const showItem = JSON.parse(JSON.stringify(item))
          this.$set(showItem, 'children', [])
          if (item.children) {
            item.children.forEach(children => {
              const perms = children.meta.perms
              const showPerms = []
              perms.forEach(data => {
                adminPerms.forEach(adminData => {
                  if (data === adminData) {
                    showPerms.push(data)
                  }
                })
              })
              if (showPerms.length > 0) {
                showItem.children.push(children)
              } else {
                this.$set(children, 'hidden', true)
              }
            })
            if (showItem.children.length > 0) {
              const showItemIndex = []
              showItem.children.forEach((data, index) => {
                if (this.adminNav === 'false') {
                  if (data.meta.title === '用户管理' || data.meta.title === '权限管理') {
                     // 把push进showItemIndex里
                       showItemIndex.push(index)
                  }
                }
              })
              // reverse()反转函数
              showItemIndex.reverse()
              showItemIndex.forEach(data => {
                // 在showItemIndex里删除用户管理、权限管理
                showItem.children.splice(data, 1)
              })
              this.showNavData.push(showItem)
            }
          }
        })
      },
      handleOpen(key, keyPath) {
      },
      handleselect(key, keyPath) {
        // window.location.href = key
        // this.reload()
        console.log(key)
        console.log(keyPath)
      },
      handleClose(key, keyPath) {
      }
    }
  }
</script>
<style>
 /* .el-submenu__title,.el-menu-item{color: #707070} */
</style>
<style scoped>
.el-menu{ border-right:none}
  .el-submenu.is-active .el-menu-item-group{
    background-color:white;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 160px;
    min-height: 100vh;
    height: 100%;
  }

  .el-menu-vertical-demo {
    min-height: 100vh;
    height: 100%;
  }

  .el-menu-item.is-active,
  .el-submenu.is-active >>> .el-submenu__title {
    background: #f2f2f2 !important;
  }

  .el-submenu .el-menu-item {
    padding-left: 60px !important;
    min-width: auto;
  }

  .el-submenu.is-active .el-menu-item {
    background: white !important;
  }

  .el-submenu.is-active .el-menu-item.is-active {
    background: white !important;
  }
  .el-submenu .el-menu-item:hover {
    padding-left: 60px !important;
    background: #aedff8 !important;
  }
  .el-menu-item i {
    padding-bottom: 4px;
  }

  .el-submenu__title i {
    padding-bottom: 4px;
  }
.el-submenu__title{font-size: 14px;}
  @media all and (max-width: 768px) {
    .el-menu--collapse {
      width: 0;
      overflow: hidden;
    }
  }
</style>
