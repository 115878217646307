const user = {
  state: {
    username: localStorage.getItem('username')
  },

  mutations: {
    SAVE_USER: (state, data) => {
      localStorage.setItem('username', data)
      state.username = data
    }
  },

  actions: {
    saveUser ({ commit }, data) {
      commit('SAVE_USER', data)
    }
  }
}

export default user
