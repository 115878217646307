import request from '@/utils/request'
/**
 * 登录
 * @param form
 * @constructor
 */
export function Login(form) {
  return request({
    // url: '/dev-api/bh/accredit',
    url: '/user/login',
    method: 'post',
    data: form
  })
}
/**
 * 注册
 * @param from
 * @constructor
 */
export function Register(from) {
  return request({
    url: '/user/shiroTest',
    method: 'post',
    data: from
  })
}

/**
 * 根据主键id查询字典表
 */
export function GetAllById(id) {
  return request({
    url: `/dev-api/getAllById?id=${id}`,
    method: 'get',
    params: id
  })
}

/**
 * 班级发布
 */
export function createClass(trainClass) {
  return request({
    url: '/class/add',
    method: 'post',
    data: trainClass
  })
}

/**
 * 查看班级--开启关闭班级评论
 */
 export function isAssesclose(query) {
  return request({
    url: '/class/assessOff',
    method: 'post',
    params: {
      ...query
    }
  })
}
/**
 * 老师列表
 */
export function teacherList(query) {
  return request({
    url: '/dev-api/findTeachers',
    method: 'get',
    params: {
      ...query
    }
  })
}

/**
 * 学员模糊查询已经发布的培训班
 */
export function getAllClassByMoHus(query) {
  return request({
    url: '/class/getAllClassByMoHus',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员模糊查询已经发布的培训班
 */
export function getAllClassByMoHu(query) {
  return request({
    url: '/class/getAllClassByMoHu',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员分组管理班级列表
 */
export function findClassInfoToGroup(query) {
  return request({
    url: '/bh/group/findClassInfoToGroup',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员--分组管理--添加分组
 */
export function addProduceGroup(data) {
  return request({
    url: '/bh/group/produceGroup',
    method: 'post',
    params: data
  })
}

export function getList() {
  return request({
    url: '/admin/users',
    method: 'get'
  })
}

export function add(from) {
  return request({
    url: '/admin/users',
    method: 'post',
    data: from
  })
}

export function update(from) {
  return request({
    url: '/admin/users',
    method: 'patch',
    data: from
  })
}

export function getOne(id) {
  return request({
    url: `/admin/users/${id}`,
    method: 'get'
  })
}

export function onDelete(id) {
  return request({
    url: `/admin/users/${id}`,
    method: 'delete'
  })
}
/**
 * 课程管理列表
 */
export function curriculumList(query) {
  return request({
    url: '/beihaieducation/course/list',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 课程管理列表中打卡时间默认值
 */
 export function getConfig() {
  return request({
    url: '/beihaieducation/course/getConfig',
    method: 'get'
  })
}
/**
 * 课程管理add
 */
export function curriculumAdd(query) {
  return request({
    url: '/beihaieducation/course/add',
    method: 'post',
    data: query
  })
}
/**
 * 课程管理remove
 */
export function removecurriculum(ids) {
  return request({
    url: `/beihaieducation/course/del/${ids}`,
    method: 'get'
  })
}
/**
 * 课程管理根据id获取编辑数据
 */
export function getcurriculum(id) {
  return request({
    url: `/beihaieducation/course/detail/${id}`,
    method: 'get'
  })
}
/**
 * 课程管理edit
 */
export function editcurriculum(query) {
  return request({
    url: '/beihaieducation/course/edit',
    method: 'post',
    data: query
  })
}
/**
 * 课程管理 --- 导出课程评估表
 */
export function getDownExcel(data) {
  return request({
    url: '/bh/admin/courseAssess/downLoadExcel',
    method: 'get',
    params: data,
    responseType: 'blob'
  })
}
/**
 * 班级管理---查看班级--查看学员--导出导出学员手册
 */
export function downStudentHandbook(id) {
  return request({
    url: '/class/downStudentHandbook?classId=' + id,
    method: 'get',
    responseType: 'blob'
  })
}
/**
 * 导出学员考勤列表
 */
export function classList(query) {
  return request({
    url: '/bh/student/findStu',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 导出个人学员考勤
 */
export function classdownLoad(query) {
  return request({
    url: '/class/stuAttendanceExcel/downLoad',
    method: 'get',
    params: {
      ...query
    },
    responseType: 'blob'
  })
}
/**
 * 导出学员考勤/课程考勤
 */
export function downLoadData(query) {
  return request({
    url: '/class/classAttendanceExcel/downLoad',
    method: 'get',
    params: {
      ...query
    },
    responseType: 'blob'
  })
}
/**
 * 创建班级
 * @param from
 * @constructor
 */
export function Getone(id) {
  return request({
    url: `/getAllTrainAgency/${id}`,
    method: 'get'
  })
}
/**
 * 立即报名
 *
 *
 */
export function Apply(student) {
  return request({
    url: '/bh/student/signUp',
    method: 'post',
    data: student
  })
}
/**
 * 请假申请
 */
export function LeAdd(stuUpdateLeave) {
  return request({
    url: `/leave/stuUpdateLeave/${stuUpdateLeave}`,
    method: 'get'
  })
}
/**
 * 个人报名信息
 */
export function Applyinto(query) {
  return request({
    url: '/bh/student/signUpAll',
    method: 'get',
    params: {
      ...query
    }
  })
}
export function classGet(id) {
  return request({
    url: `/class/getAllClassByMoHu?id=${id}`,
    method: 'get'
  })
}
/**
 * 修改班级
 */
export function Updata(id) {
  return request({
    url: `/class/getClassAll?id=${id}`,
    method: 'get'
  })
}
/**
 *个人资料
 */
export function insertStu(student) {
  return request({
    url: '/bh/student/insertStu',
    method: 'post',
    data: student
  })
}
/**
 *报名获取个人资料
 */
export function GetList(userName) {
  return request({
    url: `/bh/student/getList?userName=${userName}`,
    method: 'get'
  })
}
/**
 * 查看班级课程
 */
export function scheduleName(classId) {
  return request({
    url: `/class/scheduleName?classId=${classId}`,
    method: 'get'
  })
}
/**
 * 导出班级课程模板
 */
export function downLoadTemplate() {
  return request({
    url: '/beihaieducation/course/downLoadTemplate',
    method: 'get',
    responseType: 'blob'
  })
}
/**
 * 导入班级课程模板
 */
export function importTemplateExcel(formData, classId) {
  return request({
    url: '/beihaieducation/course/importTemplateExcel?classId=' + classId,
    method: 'post',
    data: formData
  })
}
/**
 * 管理员-课程安排上传附件
 */
export function upload(formData) {
  return request({
    url: '/upload/admin',
    method: 'post',
    data: formData
  })
}
/**
 * 管理员-课程安排上传附
 */
export function updWordUrl(query) {
  return request({
    url: '/class/intSchedule',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-课程安排-删除
 */
export function delSchedule(classId) {
  return request({
    url: '/class/delSchedule?classId=' + classId,
    method: 'get'
  })
}
/**
 * 管理员-课程安排-查看
 */
export function cheduleName(classId) {
  return request({
    url: '/class/scheduleName?classId=' + classId,
    method: 'get'
  })
}
/**
 * 根据主键id查询字典表--部门
 */
export function GetdepartmentId(id) {
  return request({
    url: `/dev-api/showTree?id=${id}`,
    method: 'get',
    params: id
  })
}
/**
 * 根据主键id查询字典表--职级缓存
 */
export function showTreesByRedis() {
  return request({
    url: '/dev-api/showTreesByRedis',
    method: 'get'
  })
}
/**
 * 根据主键id查询字典表--从缓存中部门
 */
export function showTreeByRedis() {
  return request({
    url: '/dev-api/showTreeByRedis',
    method: 'get'
  })
}
/**
 * 根据主键id查询字典表--从缓存中职级
 */
export function showTree(id) {
  return request({
    url: `/dev-api/showTrees?id=${id}`,
    method: 'get'
  })
}
/**
 * 学员端-判断是否填写个人信息
 */
export function weanStudent(userName) {
  return request({
    url: `bh/student/weanStudent?userName=${userName}`,
    method: 'get'
  })
}
/**
 * 管理员端-判断是否填写个人信息
 */
export function checkAdmin(userName) {
  return request({
    url: `/wsMessage/checkAdmin?userName=${userName}`,
    method: 'get'
  })
}
/**
 * 管理员端-个人信息回显
 */
export function getWsMessage(userName) {
  return request({
    url: `/wsMessage/getWsMessage?userName=${userName}`,
    method: 'get'
  })
}
/**
 * 拉取菜单权限信息
 * @param from
 * @constructor
 */
export function GetInfo(userName) {
  return request({
    url: `/dev-api/getInfo?userName=${userName}`,
    method: 'get'
    // params: userName
  })
}
/**
 * 管理员-修改密码
 * @param from
 * @constructor
 */
export function updPassword(from) {
  return request({
    url: '/user/updPassword',
    method: 'post',
    data: from
  })
}
/**
 * 管理员-修改填写个人资料
 * @param from
 * @constructor
 */
export function adminMessage(wsMessage) {
  return request({
    url: '/wsMessage/insWsMessage',
    method: 'post',
    data: wsMessage
  })
}
/**
 * 查看班级---刷新二维码
 */
export function refreshQR(classId) {
  return request({
    url: `/class/refreshQR?classId=${classId}`,
    method: 'get'
  })
}
/**
 * 管理员-考勤管理-下载考勤
 * @param from
 * @constructor
 */
export function PrintCurriculum(query) {
  return request({
    url: '/attendance/produce',
    method: 'post',
    data: query
  })
}
/**
 * 群发通知
 */
export function goNotice(query) {
  return request({
    url: '/bh/student/groupSendMsg',
    method: 'get',
    params: query
  })
}
