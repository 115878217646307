<template>
  <div class="footer">
  </div>
</template>

<script>
  export default {
  }
</script>

<style scoped lang="stylus">
  .footer
    height 50px
    line-height 50px
    width 100%
    font-size 14px
    text-align center
    color #777
</style>
