// import store from '@/store'
import { getCookie } from '@/utils/base'
export default {
  inserted(el, binding, vnode) {
    const { value } = binding
   // const perms = store.getters && store.getters.perms
   const adminPermsString = getCookie('perms')// 后台返回菜单权限列表
   const perms = adminPermsString.split(',')
    if (value && value instanceof Array && value.length > 0) {
      const permissions = value
      // console.log('permissions')
      // console.log(permissions)
      var hasPermission = false
      if (perms.indexOf('*') >= 0) {
        hasPermission = true
      } else {
        hasPermission = perms.some(perm => {
          return permissions.includes(perm)
        })
      }
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error('need perms! Like v-permission=[GET /aaa,POST /bbb]')
    }
  }
}
